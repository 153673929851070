/* VENDOR - Default fall-backs and external files.
========================================================================== */
/* BASE - Base Variable file along with starting point Mixins and Placeholders.
========================================================================== */
@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Regular.woff") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-SemiBold.woff") format("woff2");
  font-weight: bold;
  font-style: normal; }

/* FRAMEWORK - Structure and layout files. Partials
========================================================================== */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  scroll-behavior: auto !important; }

*,
*::after,
*::before {
  box-sizing: inherit; }

*:focus {
  outline: none; }

* ::selection {
  background: #ffffff;
  color: #101010; }

body {
  background: #101010;
  color: #ffffff !important;
  margin: 0;
  font-family: "Inter", "Helvetica", sans-serif;
  font-size: 16px; }

.background {
  width: 100%;
  z-index: 0; }

.main-title {
  font-size: 6em;
  line-height: 6.5rem;
  color: #ffffff;
  font-weight: 400;
  z-index: 0; }

.h-100 {
  height: 100%; }

.inline-block {
  display: inline-block; }

.break-word {
  word-wrap: break-word !important; }

.parent-center,
.parent-center-2 {
  position: relative;
  height: 100vh;
  width: 100vw; }

.child-center,
.child-center-tweet,
.child-center-2 {
  z-index: 1;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%); }

a {
  text-decoration: none !important;
  color: #ffffff !important; }

input:focus {
  outline: none !important; }

.none {
  list-style-type: none;
  padding-left: 0; }

.center {
  vertical-align: middle;
  margin: auto; }

.m-auto {
  margin: auto; }

.font {
  font-family: "Inter", "Helvetica", sans-serif; }

.italic {
  font-style: italic; }

.bold {
  font-weight: 700 !important; }

.medium {
  font-weight: 600 !important; }

.regular {
  font-weight: 400; }

.light {
  font-weight: lighter !important; }

.h-pt {
  font-size: 7.5em;
  line-height: 1.3;
  letter-spacing: -1px;
  color: #ffffff;
  font-weight: 400;
  white-space: pre-wrap; }

.h1,
h1 {
  font-size: 6.25em;
  line-height: 1;
  letter-spacing: -1px;
  color: #ffffff;
  font-weight: 400;
  white-space: pre-wrap; }

.h2,
h2 {
  font-size: 2.5em;
  line-height: 1.2;
  color: #ffffff;
  font-weight: 400;
  white-space: pre-wrap; }

.h3,
h3 {
  font-size: 1.25em !important;
  line-height: 1.3;
  font-weight: 400;
  color: #ffffff; }

.h4,
h4 {
  font-size: 1.625em;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 400;
  white-space: pre-wrap; }

.h5,
h5 {
  font-size: 1.125em;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 400;
  white-space: pre-wrap; }

.h6,
h6 {
  font-size: 0.75em;
  line-height: 1.2;
  color: #ffffff;
  font-weight: 400 !important;
  white-space: pre-wrap; }

.h7 {
  font-size: 0.625em;
  line-height: 1;
  color: #ffffff;
  font-weight: 400;
  white-space: pre-wrap; }

.h-error {
  max-width: 350px;
  font-size: 0.75em;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 400;
  white-space: pre-wrap;
  margin: auto; }

.p-traits {
  font-size: 1em;
  line-height: 1.5;
  font-weight: 400;
  color: #ffffff; }

p {
  font-size: 1em !important;
  line-height: 1.5;
  font-weight: 400;
  color: #ffffff; }

.color-bg {
  color: #101010; }

.text-secoundary {
  font-size: 0.875em !important;
  line-height: 1.5;
  font-weight: 400;
  color: #ffffff; }

.section-year {
  height: 100%; }

.text-year {
  display: flex;
  color: #101010; }

.color-text-secoundary {
  color: #8D8D92; }

.box-text-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.h-title {
  font-size: 2.8125em;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 400;
  white-space: pre-wrap; }

.uppercase {
  text-transform: uppercase; }

.container-fluid {
  padding-left: 0;
  padding-right: 0; }

.terms {
  max-width: 605px;
  text-align: left;
  display: inline-block; }

.p-home {
  max-width: 500px;
  text-align: left;
  display: inline-block; }

.py-5-0 {
  padding: 3em 0; }

.concatenated-hash {
  max-width: 1000px;
  text-align: left;
  display: block;
  margin: 0 auto;
  white-space: initial;
  word-wrap: break-word; }

.color-gold {
  color: #FFC53C; }

.bg-white {
  background-color: inherit !important;
  width: inherit;
  height: inherit; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type="number"] {
  appearance: textfield; }

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #e1e3f2;
  opacity: 1;
  /* Firefox */ }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e1e3f2; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #e1e3f2; }

hr {
  margin: 0.5rem 0;
  color: #8D8D92; }

input[type="number"],
textarea {
  position: relative;
  border: none;
  text-align: center;
  color: #e1e3f2 !important;
  font-size: 18px;
  background-color: #5a6fe0 !important;
  padding: 0rem;
  width: 54px; }

.tweet > input[type="number"],
.tweet > textarea {
  position: relative;
  border: none;
  text-align: left;
  color: #101010 !important;
  font-size: 18px;
  background-color: #ffffff !important;
  padding: 0rem;
  width: 54px; }

.ipcenter > input[type="number"],
.ipcenter > textarea {
  position: relative;
  border: none;
  text-align: left;
  color: #ffffff !important;
  font-size: 18px;
  background-color: #1a1a1a !important;
  padding: 0rem;
  width: 54px; }

.faceID {
  position: fixed;
  top: 310px;
  left: 46px; }

.vending-lever-1 {
  z-index: 2;
  cursor: pointer !important;
  width: 46px;
  position: fixed;
  top: 298px;
  left: 126px; }

.vending-lever-2 {
  z-index: 2;
  cursor: pointer !important;
  width: 46px;
  position: fixed;
  top: 298px;
  left: 180px; }

.vending-download {
  z-index: 2;
  cursor: pointer !important;
  width: 150px;
  position: fixed;
  top: 391px;
  left: 30px; }

.wtf-s1,
.wtf-s2,
.wtf-s3 {
  z-index: 4;
  position: fixed;
  width: 200px; }

.wtf-s1 {
  top: 143px;
  right: 302px; }

.wtf-s2 {
  top: 148px;
  right: -144px; }

.wtf-s3 {
  top: 395px;
  right: 320px; }

.info-s1 {
  z-index: 0;
  position: fixed;
  top: 0;
  right: 510px; }

.info-s1-img {
  width: 120px; }

.info-s2 {
  z-index: 0;
  position: fixed;
  top: 0;
  left: 510px;
  width: 270px; }

.stage2-title {
  display: inline-flex;
  justify-content: center;
  width: 100%; }

.pt-header-pill {
  padding-top: 110px; }

.pill {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a1a1a;
  border-radius: 20px;
  flex-shrink: 0;
  width: 100%; }

.pill-ip {
  background: #1a1a1a;
  border-radius: 20px;
  width: 100%; }

.pill-center {
  display: grid;
  justify-content: center;
  justify-items: center; }

.pill-center-inline {
  text-align: center;
  justify-content: center;
  justify-items: center; }

.pill-img {
  height: 250px !important;
  width: auto;
  display: inline-block;
  border-radius: 16px; }

.pill-ip-img,
.pill-ip-img-loading {
  width: 100%;
  height: 198px;
  border-radius: 16px; }

.pill-ip-img {
  object-fit: cover;
  padding: 0;
  border: none; }

.pill-ip-img-loading {
  display: flex;
  justify-content: center;
  padding: 0;
  object-fit: cover;
  background-color: #ffffff;
  border: 1px solid #ebebeb; }

.pill-active {
  border: 1px solid #ffffff !important;
  padding: 5px !important; }

.pill-grid {
  display: inline-flex;
  width: 67.2px; }

.pill-ip-img-sm,
.pill-ip-img-loading-sm {
  width: 67.2px;
  height: 67.2px;
  border-radius: 1000px; }

.pill-ip-img-sm {
  object-fit: cover;
  padding: 0;
  border: none;
  cursor: pointer; }

.pill-ip-img-loading-sm {
  display: flex;
  justify-content: center;
  padding: 0;
  object-fit: cover;
  background-color: #ffffff;
  border: 1px solid #ebebeb; }

.loader-img-sm {
  width: 18px !important;
  height: auto;
  display: inline-block; }

.pill-img-s3 {
  height: auto !important;
  width: 150px;
  display: inline-block; }

.w-100 {
  width: 100vw !important;
  display: inline-flex;
  justify-content: center; }

.pill-color-text {
  color: #717171; }

.stages-w {
  width: 650px; }

.ip-w,
.ip-w-home {
  width: 650px; }

button {
  background-color: #101010;
  color: #ffffff !important;
  padding: 10px 16px;
  line-height: 1.125em;
  border: 1px solid #ffffff;
  border-radius: 6rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem !important; }

button:hover {
  border: 1px solid #ffffff;
  background-color: #ffffff;
  color: #101010; }

button:active {
  transform: scale(0.95); }

button:visited {
  background-color: #ffffff !important;
  color: #e8e8e8; }

.close-button {
  background-color: #101010;
  color: #ffffff !important;
  padding: 8px 4px;
  line-height: 10px;
  border-radius: 100rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.3rem !important; }

.close-button:hover {
  border: 1px solid #101010;
  background-color: #ffffff;
  color: #101010 !important; }

.accordion-button:hover {
  color: #101010 !important; }

.accordion-button:active {
  transform: none; }

select {
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: 2px solid #ffffff;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #101010; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #ffffff;
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #101010 inset;
  transition: background-color 5000s ease-in-out 0s; }

header {
  background: #101010; }

.bg-color {
  background: #101010; }

.color-white {
  background: #ffffff !important; }

.color-black {
  background: #101010; }

.trait-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }

.h-center {
  align-items: center;
  min-height: calc(100vh - 80px - 256px); }

.h-vertical {
  display: inline-flex;
  align-items: center;
  height: 100%; }

.table-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.zindex {
  z-index: 100; }

.logo {
  width: auto;
  height: 45px !important; }

.logo:hover {
  transform: scale(0.97); }

.logo-mobile {
  width: 65% !important;
  height: auto; }

.monolith {
  width: auto;
  height: 300px !important; }

.monolith-error {
  width: auto;
  height: 40px !important;
  transform: rotate(180deg); }

.bored-footer {
  text-align: center;
  width: auto;
  height: 150px !important; }

.bored-error {
  width: auto;
  height: 150px !important; }

.future-icon {
  width: auto;
  height: 50px !important; }

.tool-icon {
  cursor: pointer;
  width: auto;
  height: 35px !important; }

.tool-box-icon {
  cursor: pointer;
  padding: 1rem 0;
  display: inline-flex;
  flex-direction: column; }

.tool-box-icon:hover > .tool-icon {
  padding: 2px; }

.twitter-box > * ::selection {
  background: #101010;
  color: #ffffff; }

.twitter-box {
  background-color: #ffffff;
  color: #111111;
  border-radius: 20px;
  padding: 1rem 1.75rem !important;
  z-index: 1100 !important; }

.twitter-logo {
  width: 25px !important;
  height: auto;
  display: inline-block; }

.tweet-icon {
  width: 28px !important;
  height: auto;
  display: inline-block;
  cursor: pointer; }

.tweet-icon-sm {
  width: 26px !important; }

.tweet-icon:hover {
  transform: scale(1.05); }

.tweet-icon:active {
  transform: scale(0.95); }

.tweet-text {
  display: inline-block;
  font-size: 18px;
  line-height: 1.3;
  color: #101010;
  font-weight: 400;
  white-space: pre-wrap;
  max-width: 410px; }

.tweet-number {
  display: inline-block;
  font-size: 18px;
  line-height: 1.3;
  color: #101010;
  font-weight: 400;
  white-space: pre-wrap; }

.tweet-number-bg {
  display: inline-block;
  font-size: 18px;
  line-height: 1.3;
  color: #ffffff;
  font-weight: 400;
  white-space: pre-wrap; }

.tweet-img {
  width: 220px;
  height: 270px;
  border-radius: 16px;
  object-fit: cover;
  padding: 0;
  border: none; }

.img-10k {
  width: 300px;
  height: 300px;
  border-radius: 0px;
  object-fit: cover;
  padding: 0;
  border: none; }

.i-10k-center {
  display: flex !important;
  flex-direction: column;
  align-items: center; }

.twitter-button {
  color: #ffffff;
  background-color: #4a99e9;
  border: 1px solid #4a99e9;
  font-size: 16px;
  font-weight: 600;
  line-height: 1em;
  display: inline-block;
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 6rem; }

.twitter-button:hover {
  background-color: #428ad2;
  border: 1px solid #4a99e9; }

.tweet-img-loading {
  display: flex;
  justify-content: center;
  padding: 0;
  object-fit: cover;
  background-color: #ffffff;
  width: 220px;
  height: 270px;
  border-radius: 16px;
  border: 1px solid #ebebeb; }

.img-10k-loading {
  display: flex;
  justify-content: center;
  padding: 0;
  object-fit: cover;
  background-color: #ffffff;
  width: 300px;
  height: 300px;
  border-radius: 16px;
  border: 1px solid #ebebeb; }

.loader-img {
  width: 30px !important;
  height: auto;
  display: inline-block; }

.stage3-img {
  width: 190px !important;
  height: auto;
  display: inline-block; }

.animaglia-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh; }

.animaglia-img {
  width: 650px !important;
  height: auto;
  display: inline-block; }

.animaglia-color {
  background-color: #1c5bff !important; }

.stages-img {
  width: 210px !important;
  height: auto;
  display: inline-block; }

.buy-blur {
  color: #101010;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 1em;
  display: inline-flex;
  align-items: center;
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 8px; }

.buy-opensea {
  color: #ffffff;
  background-color: #101010;
  border: 1px solid #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 1em;
  display: inline-flex;
  align-items: center;
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 8px; }

.blur-img {
  width: auto;
  height: 28px;
  display: inline-block; }

.buy-opensea:hover,
.buy-blur:hover {
  transform: scale(0.95); }

.opensea-img {
  width: auto;
  height: 30px;
  display: inline-block; }

.yay-footer-img {
  width: 450px !important;
  height: auto;
  display: inline-block; }

.yaypegs-box-below {
  display: flex;
  justify-content: center; }

.yaypegs {
  width: 100% !important;
  height: auto;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff; }

.yaypegs-custom {
  width: 100% !important;
  height: auto;
  border-radius: 8px; }

.yaypegs-r0 {
  width: 100% !important;
  height: auto; }

.round-all {
  border: 1px solid #ffffff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%; }

.yaypegs-icon {
  cursor: pointer;
  width: auto;
  height: 30px !important; }

.yaypegs-gif {
  max-width: calc(18vw);
  width: 100% !important;
  height: auto;
  border-radius: 12px; }

.yaypegs-gif-sm {
  max-width: calc(12vw);
  width: 100% !important;
  height: auto;
  border-radius: 12px; }

.v-line {
  border-right: 1px solid #ffffff; }

.h-line {
  border-bottom: 2px solid #ffffff; }

.h-line-none {
  border-bottom: 2px solid none; }

.about-line {
  margin: 1rem 0;
  color: #616161; }

.about {
  font-size: 0.9375rem;
  text-align: left;
  display: inline-block; }

.about-title {
  height: 40px !important;
  width: auto;
  display: inline-block; }

.about-date {
  height: 30px !important;
  width: auto;
  display: inline-block; }

.about-year {
  height: 28px !important;
  width: auto;
  display: inline-block;
  padding: 2px;
  padding-bottom: 7px !important; }

.about-subtitle {
  height: 25px !important;
  width: auto;
  display: inline-block; }

.about-box {
  width: 500px; }

.about-text {
  white-space: pre-line; }

.about-reply {
  border: 1px solid #323233;
  border-radius: 8px;
  padding: 1rem 1.75rem !important;
  z-index: 1100 !important; }

.about-grey {
  color: #616161; }

.about-img {
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
  padding: 0;
  border: none; }

.about-icon {
  width: 28px !important;
  height: auto;
  display: inline-block;
  cursor: pointer; }

.p-grey {
  font-size: 18px;
  color: #8d8d92; }

.p-grey-subtitle {
  font-size: 14px;
  color: #8d8d92; }

.line {
  margin: 0rem;
  border-top: 0px solid #ffffff; }

.border-y {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff; }

.sides-borders {
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  background-color: none; }

.section-link {
  min-height: calc(100vh / 2) !important;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.section-link-future {
  display: flex;
  flex-direction: column; }

.section-error {
  height: calc(100vh - 80px - 200px - 50px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.section-error-menu {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.brand-col-colors {
  z-index: -1; }

.brand-colors {
  height: 35px;
  width: 100vw !important;
  border-left: 1px solid #101010 !important; }

.error-col-color {
  position: fixed;
  top: 0;
  z-index: -1; }

.error-color {
  height: 100vh;
  width: 100vw !important;
  border-left: 1px solid #101010 !important; }

.yaypegs-circle {
  height: auto;
  width: 100px !important;
  border: 1px solid #ffffff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%; }

.inline-gallery {
  display: inline-flex;
  margin: calc(-1.3vw); }

.inline-gallery-m {
  margin-left: calc(1vw); }

.yaypegs-circle-inline {
  height: auto;
  width: calc(12vw) !important;
  border: 1px solid #ffffff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%; }

.section-blank {
  height: calc(100vh - 80px - 100vh / 2 - 270px) !important; }

.isotype {
  cursor: pointer;
  width: 70px;
  height: auto; }

.isotype-sm {
  width: 20px;
  height: auto; }

.line-logo {
  width: auto;
  height: 70px; }

.section-monolith {
  height: calc(100vh / 4);
  width: auto; }

.section-box {
  width: 100% !important;
  height: auto;
  border-radius: 12px; }

.magic-machine {
  width: 100vw !important;
  min-height: 100vh !important;
  object-fit: cover; }

.magic-machine-mobile {
  width: 100vw !important;
  height: calc(100vh - 90px) !important;
  object-fit: cover; }

.displayNone {
  display: none; }

.vending-machine {
  width: 326.5px !important;
  min-height: 100% !important;
  object-fit: cover; }

.vending-machine-mobile {
  width: 350px !important;
  min-height: 100% !important;
  object-fit: cover; }

.gif-machine {
  position: fixed;
  z-index: -2;
  top: 82px;
  left: 27px;
  width: 202px !important;
  height: 202px !important;
  object-fit: cover; }

.img-right {
  text-align: right;
  padding-right: 3em; }

.collection-home {
  width: 100% !important;
  height: auto; }

.pt-header {
  padding-top: 80px; }

.pt-header-menu {
  padding-top: 180px; }

.pb-header {
  padding-bottom: 80px; }

.tool-menu {
  z-index: -1;
  height: auto;
  background-color: #2C2C2C; }

.menu-center {
  height: 100%;
  display: flex;
  align-items: center; }

.menu-left {
  justify-content: left; }

.fixed-top-tool {
  position: fixed;
  top: 80px;
  right: 0;
  left: 0;
  z-index: 1030; }

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  line-height: 80px;
  height: 80px; }

.menu-center {
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.bubble {
  color: #ffffff;
  background-color: none;
  display: inline-block;
  padding: 10px 16px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 6rem;
  line-height: 1.125em; }

.bubble:hover {
  color: #101010 !important;
  background-color: #ffffff !important; }

.bubble-default {
  color: #ffffff;
  background-color: #101010;
  display: inline-block;
  padding: 10px 16px;
  border: 1px solid #101010;
  box-sizing: border-box;
  border-radius: 6rem;
  line-height: 1.125em; }

.bubble-default:hover {
  color: #101010 !important; }

.bubble-error {
  color: #ffffff !important;
  background-color: none;
  display: flex;
  padding: 10px 16px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 6rem;
  line-height: 1.125em; }

.bubble-error-black {
  color: #101010 !important;
  background-color: #f8f8f9;
  display: flex;
  padding: 10px 16px;
  border: 1px solid #101010;
  box-sizing: border-box;
  border-radius: 6rem;
  line-height: 1.125em; }

.bubble-innactive {
  color: #2C2C2C;
  background-color: none;
  border: 1px solid #2C2C2C;
  display: flex;
  padding: 10px 16px;
  box-sizing: border-box;
  border-radius: 6rem;
  line-height: 1.125em; }

.active {
  color: #101010 !important;
  background-color: #ffffff;
  display: inline-block;
  padding: 10px 16px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 6rem; }

.no-active {
  padding: 0; }

.navbar-center {
  justify-content: center; }

.navbar-end {
  justify-content: flex-end; }

.navbar-start {
  justify-content: flex-start; }

.navlinks {
  display: inline-block;
  padding: 10px 16px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 6rem; }

.navlinks-innactive {
  color: #2C2C2C;
  background-color: none;
  border: 1px solid #2C2C2C;
  border-radius: 6rem;
  display: inline-block;
  padding: 10px 16px;
  box-sizing: border-box;
  line-height: 1.125em; }

.navlinks:hover {
  color: #101010 !important; }

.navlinks:hover > h5 {
  color: #101010 !important; }

a.link {
  color: #ffffff !important;
  display: inline-flex; }

a.link-text {
  color: #FFC53C !important;
  text-transform: none;
  display: inline-flex; }

a .link-hash {
  color: red !important;
  text-transform: none;
  display: inline-flex;
  text-decoration: underline !important; }

.navlinks:hover,
.link:hover {
  color: #ffffff !important; }

.link-text {
  text-transform: uppercase;
  cursor: pointer;
  z-index: 10000 !important; }

.link-text-hash {
  text-decoration: underline !important; }

.hyperlink {
  text-decoration: underline !important;
  text-underline-offset: 0.1rem; }

.underline:hover {
  text-decoration: underline;
  text-underline-offset: 0.1rem; }

.tabbar-nav {
  background-color: #211e1c;
  height: 90px;
  line-height: 50px;
  align-items: center;
  padding: 0; }

.pb-tabbar {
  padding-bottom: 0px; }

.tabbar-icon {
  height: auto; }

.tabbar-text .h7 {
  color: #b3b3b3 !important; }

.tabbar-nav .h7 {
  color: #b3b3b3; }

.tabbar-nav .active {
  color: #ffffff !important;
  background: none !important;
  border: none !important;
  padding: inherit !important; }

.tabbar-text-active,
[aria-current|="page"] .h7 {
  color: #ffffff !important; }

.box {
  display: inline-block;
  padding: 75px 100px;
  border-radius: 12px;
  border: 1px solid #ffffff; }

.box-line {
  display: inline-block;
  border-radius: 12px;
  border: 1px solid #ffffff; }

.box-line-sq {
  max-width: calc(20vw);
  min-width: 250px;
  width: 100% !important;
  height: auto;
  display: inline-block;
  border-radius: 12px;
  border: 1px solid #ffffff; }

.art-gif {
  max-width: calc(20vw);
  min-width: 250px;
  width: 100% !important;
  height: auto;
  display: inline-block; }

.p-table {
  border-radius: 20px;
  color: #3d3d3d !important;
  background-color: none;
  border: 1px solid #3d3d3d; }

.p-table .h-pt,
.p-table h4,
.p-table h5 {
  color: #3d3d3d !important; }

.p-table-center {
  display: flex;
  justify-content: center; }

.fixed-top {
  z-index: 1; }

.fixed-bottom {
  z-index: 0; }

.bubble-index {
  z-index: 1100 !important; }

.logo-footer {
  text-align: center;
  width: 100% !important;
  height: auto; }

.footer-end {
  text-align: end; }

.child-footer {
  width: 100%;
  z-index: 0;
  display: block;
  position: absolute;
  bottom: 0; }

.footer-block {
  display: block; }

.iekbcc0 .ju367v4i {
  display: none !important; }

.ju367vex {
  color: #101010 !important; }

.ju367v12 {
  color: #d5cafe !important; }

[data-rk] .ju367v25 {
  height: auto !important; }

[data-testid|="rk-connect-button"],
[data-testid|="rk-account-button"] > .iekbcc0 {
  font-size: 1em !important;
  font-weight: 400 !important;
  font-family: "Inter", "Helvetica", sans-serif !important;
  line-height: 1.125em !important;
  color: #101010 !important;
  background: #ffffff !important;
  display: inline-block !important;
  padding: 10px 16px !important;
  border: 1px solid #ffffff !important;
  box-sizing: border-box !important;
  border-radius: 6rem !important; }

.iekbcc0.ju367v2b {
  color: #101010 !important; }

[data-rk] .ju367v6 {
  display: none !important; }

.happy {
  cursor: pointer;
  width: 30px;
  margin-left: -15px; }

.darkmode {
  background: #070707 !important;
  color: #f6f6f6 !important; }

.isotype-happyface {
  animation: spinner 1s; }

.isotype-nuetralface {
  animation: spinnerBack 1s; }

@keyframes spinner {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg); } }

@keyframes spinnerBack {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  to {
    -moz-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    transform: rotateY(360deg); } }

.bg-color {
  background-color: #101010; }

.bg-color-none {
  background-color: none; }

.change-color {
  width: 100vw;
  height: 100vh;
  animation: changeColor 15s infinite !important; }

@keyframes changeColor {
  0% {
    background-color: #617bff; }
  50% {
    background-color: #495f9d; } }

@media (max-width: 1200px) {
  .img-brand {
    height: 350px; } }

@media (max-width: 992px) {
  .ip-w-home {
    width: auto;
    min-width: 300px; }
  .animaglia-color {
    background-color: #1c5bff !important;
    height: 100vh; }
  .container-img {
    width: 50% !important; }
  .animaglia-center {
    height: calc(100vh - 90px) !important; }
  .pt-header-pill {
    padding-top: 50px; }
  .tweet-img-loading {
    max-width: 250px;
    width: 100%; }
  .tweet-img {
    max-width: 250px;
    width: 100%; }
  .parent-center-2 {
    position: relative;
    height: 100%;
    width: 100%;
    display: inline-flex;
    justify-content: center; }
  .child-center-2 {
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    transform: none; }
  .yay-footer-img {
    width: 80% !important;
    height: auto;
    display: inline-block; }
  .bg-white {
    background-color: #ffffff !important; }
  .child-center-tweet {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: inherit;
    top: 0;
    left: 0;
    transform: none; }
  .twitter-box {
    max-width: 600px;
    border-radius: 0px;
    padding: 1.25rem 1.25rem !important;
    z-index: 1 !important; }
  .fixed-bottom {
    z-index: 1030; }
  .mobile-parent-center {
    position: inherit !important;
    height: 100%;
    width: 100%; }
  .hash-xl {
    max-width: 300px; }
  .sides-borders {
    border: 0; }
  .section-link {
    min-height: calc(100vh / 3) !important; }
  .section-error {
    height: calc(100vh - 90px - 73px - 50px); }
  .logo {
    width: 150px !important; }
  .tool-menu {
    height: 80px;
    line-height: 80px; }
  .tool-icon {
    height: 35px !important; }
  .tool-textIcon {
    display: flex; }
  .monolith {
    width: auto;
    height: 200px !important; }
  .monolith-error {
    height: 30px !important; }
  .table-center {
    position: inherit;
    top: none;
    transform: none; }
  .box {
    display: inline-block;
    padding: 50px 50px;
    border-radius: 12px;
    border: 1px solid #ffffff; }
  .art-gif {
    padding: 0 2em;
    max-width: 100%;
    width: 100% !important;
    height: auto;
    display: inline-block; }
  .h-center {
    min-height: calc(100vh - 243px); }
  .yaypegs-gif {
    max-width: calc(40vw); }
  .yaypegs-gif-sm {
    max-width: calc(25vw); }
  .line {
    border-top: 1px solid #ffffff; }
  .pt-header {
    padding-top: 0px; }
  .pb-header {
    padding-bottom: 0px; }
  .pt-header-menu {
    padding-top: 100px; }
  .pb-tabbar {
    padding-bottom: 90px; }
  .border-y {
    border-top: 0;
    border-bottom: 0; }
  .fixed-top-tool {
    position: fixed;
    top: 0px;
    right: 0;
    left: 0;
    z-index: 1030; } }

@media (max-width: 768px) {
  .pill-grid {
    width: 12.2vw; }
  .pill-ip-img-sm,
  .pill-ip-img-loading-sm {
    width: 12.2vw;
    height: 12.2vw;
    border-radius: 1000px; }
  .pill-ip-img-loading,
  .pill-ip-img {
    height: 30vw; }
  .pt-header-pill {
    padding-top: 0px; }
  .main-title {
    font-size: 3em;
    line-height: 1em; }
  .yaypegs-gif {
    max-width: calc(60vw); }
  .yaypegs-gif-sm {
    max-width: calc(50vw); }
  .bored-error {
    width: auto;
    height: 100px !important; }
  .img-right {
    text-align: right;
    padding-right: 0em; }
  .py-5-0 {
    padding: 0em 0; }
  .h1,
  h1 {
    font-size: 3em; }
  .h2,
  h2 {
    font-size: 1.5em; }
  .p-traits {
    font-size: 0.75em;
    line-height: 1.2;
    color: #ffffff;
    font-weight: 400;
    white-space: pre-wrap; } }

@media (max-width: 576px) {
  .about {
    padding: 0 1rem; }
  .about-box {
    width: 100%;
    white-space: pre-wrap; }
  .tweet-text {
    display: block;
    font-size: 18px;
    line-height: 1.3;
    color: #101010;
    font-weight: 400;
    white-space: pre-wrap;
    padding: 0.25rem 0 !important; }
  .pfp {
    width: 150px;
    height: auto;
    border-radius: 20px; }
  .brand-colors {
    height: 15px; }
  .happy {
    margin-left: 0px; }
  .img-brand {
    height: 270px; } }

/* MODULES - Re-usable site elements.
========================================================================== */
.form-select {
  width: auto;
  border: 1px solid #101010;
  margin: 0;
  display: inline-block; }
